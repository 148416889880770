html,
body,
#root {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 18px;
    height: 100%;
    background-color: #f7fafc;
}

#root {
    --amplify-font-family: 'Open Sans', sans-serif;
    --amplify-primary-color: #00BEFF;
    --amplify-primary-tint: #0392c2;
}

.fa-spin {
    -webkit-animation: fa-spin 700ms infinite linear;
    animation: fa-spin 700ms infinite linear;
}

.shake-animation {
    animation: shake 250ms 1 ease-out;
}

@keyframes shake {
    0% { transform: translate(4px); }
    20% { transform: translate(-4px); }
    40% { transform: translate(2px); }
    60% { transform: translate(-2px); }
    80% { transform: translate(1px); }
    100% { transform: translate(0px); }
}

amplify-authenticator {
    --container-height: 100%;
}
